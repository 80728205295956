import { Injectable } from "@angular/core";
import {BoardGameBasicResponse, GamePlayResponse} from "../model/responses";
import {BehaviorSubject} from "rxjs";
import {UserBasicInfo} from "../model/user";

@Injectable()
export class CurrentViewDataService {

    public selectedMainGame?: BoardGameBasicResponse
    public selectedMainGameObservable = new BehaviorSubject<BoardGameBasicResponse | undefined>(undefined)
    public selectedDrawerGame?: BoardGameBasicResponse
    public selectedDrawerGameObservable = new BehaviorSubject<BoardGameBasicResponse | undefined>(undefined)

    public selectedUser?: UserBasicInfo
    public selectedUserObservable = new BehaviorSubject<UserBasicInfo | undefined>(undefined)
    public selectedGame?: BoardGameBasicResponse
    public selectedGameObservable = new BehaviorSubject<BoardGameBasicResponse | undefined>(undefined)
    public selectedPlay?: GamePlayResponse
    public selectedPlayObservable = new BehaviorSubject<GamePlayResponse | undefined>(undefined)

    public selectUser(user?: UserBasicInfo) {
        this.selectedUser = user
        this.selectedUserObservable.next(user)
    }

    public selectGame(game?: BoardGameBasicResponse, fromDrawer: boolean = false) {
        if (fromDrawer) {
            this.selectedDrawerGame = game
            this.selectedDrawerGameObservable.next(game)
        } else {
            this.selectedMainGame = game
            this.selectedMainGameObservable.next(game)
        }
        this.selectedGame = this.selectedMainGame || this.selectedDrawerGame
        this.selectedGameObservable.next(this.selectedGame)
    }

    public selectPlay(play?: GamePlayResponse) {
        this.selectedPlay = play
        this.selectedPlayObservable.next(play)
        if (play) {
            this.selectGame(play.game)
        } else {
            this.selectGame(undefined)
        }
    }
}
